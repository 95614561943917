<template lang='pug'>
div
  transition(name='fade')
    div(v-if="visible", class="modal-mask", @click="safeClose")
  transition(name="popup")
    div(v-if="visible", class="popup", ref="popup", :style="{ top: `${position.top}px`, left: `${position.left}px` }")
      .header-area
        span {{tableLabel}} - {{ elementLabel }}
      .input-area(:class="{ invalid: errors }")
        textarea(v-if="showTextArea", v-model="content", @input="inputEvent")
        div(v-else-if="showSelect")
          select(v-model="dbVal",
            v-on:change="dependentUIEvent('selectChange')")
            option(value="")
            option(v-for="option in options", :key="option.key", :value="option.key") {{ optionText(option)}}

        input(v-else, v-model="content", @input="inputEvent")
      .button-area
        ui-button(v-on:buttonClicked="saveEdit", :disabled='!!errors') Save
        ui-button(v-on:buttonClicked="closePopup") Cancel
</template>

<script>
import EventBus, { EHR_INLINE_EDIT_EVENT } from '@/helpers/event-bus'
import UiButton from '@/app/ui/UiButton.vue'
import EhrDefs from '@/ehr-definitions/ehr-defs-grid'
import { t18EhrText, t18ElementLabel, t18SelectOption } from '@/helpers/ehr-t18'
import ehrValidations from '@/ehr-definitions/ehr-validations'
import EhrTypes from '@/ehr-definitions/ehr-types'

export default {
  components: { UiButton },
  data () {
    return {
      content: '',
      dbVal: '',
      element: {},
      elementLabel: '',
      errors: undefined,
      inputType: 'text',
      isDirty: false,
      position: { top: 0, left: 0 },
      saveCallback: null,
      tableLabel: '',
      visible: false
    }
  },
  computed: {
    showTextArea () { return this.inputType === EhrTypes.dataInputTypes.textarea},
    showSelect  () { return this.inputType === EhrTypes.dataInputTypes.select},
    options () {
      return this.element.options
    }
  },
  methods: {
    closePopup () {
      this.visible = false
      this.isDirty = false
    },
    dependentUIEvent() {
      console.error('Coding not complete. Need to emit event like in EhrDependent')
    },
    handleEsc (event) {
      if (event.key === 'Escape') {
        this.safeClose()
      } else {
        // const shiftEnter = event.getModifierState('Shift') && event.key === 'Enter'
        const enterSave = !event.getModifierState('Shift') && event.key === 'Enter'
        if (enterSave) {
          this.saveEdit()
        }
      }
    },
    inputEvent () {
      this.isDirty = true
      const validator = ehrValidations[this.element.validation]
      if (validator) {
        this.errors = validator(this.elementLabel, this.content)
      } else {
        this.errors = undefined
      }
    },
    openEditEventHandler (payload) {
      if (this.isDirty && !confirm('You have unsaved changes. Discard them?')) return
      const {
        content, dbVal, relativeToId, saveCallback,
        pageKey, tableKey, elementKey
      } = payload
      const pageTable = EhrDefs.getPageTable(pageKey, tableKey)
      this.element = EhrDefs.getPageChildElement(pageKey, elementKey)
      this.inputType = this.element.inputType
      this.content = content
      this.dbVal = dbVal
      this.tableLabel = t18ElementLabel(pageTable)
      this.elementLabel = t18ElementLabel(this.element)
      this.saveCallback = saveCallback
      this.isDirty = false
      // Position
      const tdElement = document.getElementById(relativeToId)
      if (tdElement) {
        const rect = tdElement.getBoundingClientRect()
        const top = rect.top + window.scrollY // + tdElement.offsetHeight;
        let left = rect.left + window.scrollX;
        this.visible = true // render the popup now
        this.$nextTick(() => {
          // can get the popup width now that it is rendered
          const popupWidth = this.$refs.popup.offsetWidth;
          // Adjust position if it overflows
          if (left + popupWidth > window.innerWidth) {
            left = window.innerWidth - popupWidth - 10;
          }
          this.position = { top, left };
        })
      }
    },
    optionText ( option ) { return t18SelectOption(this.element, option)},
    safeClose () {
      let msg = t18EhrText()['UnsavedEdit']
      if (this.isDirty && !confirm(msg)) return
      this.closePopup()
    },
    saveEdit () {
      let newValue = this.content
      if (this.showSelect) {
        newValue = this.dbVal
      }
      if (this.saveCallback) {
        this.saveCallback(newValue)
      }
      this.closePopup()
    }
  },
  mounted () {
    EventBus.$on(EHR_INLINE_EDIT_EVENT, this.openEditEventHandler)
    window.addEventListener('keydown', this.handleEsc)
  },
  beforeDestroy () {
    EventBus.$off(EHR_INLINE_EDIT_EVENT, this.openEditEventHandler)
    window.removeEventListener('keydown', this.handleEsc)
  },

}
</script>
<style lang='scss' scoped>

.modal-mask {
  z-index: 900;
}
.popup {
  z-index: 1000;
}

/* Styles for Popup */

.popup {
  position: absolute;
  background-color: white;
  border: 2px solid #aaa;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  input{
    min-width: 20rem;
    max-width: 50rem;
    height: fit-content;
    white-space: normal; /* Allows text to wrap */
  }
  .button-area {
    button {
      margin-right: 8px; /* Adds space between Save and Cancel buttons */
      padding: 0 5px;
      height: min-content;
      margin-top: 5px;
    }
  }

  button:last-child {
    margin-right: 0; /* Removes extra space after the last button */
  }
}

/* Styles for Modal Mask */
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
}
.modal-mask {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ********** */
/* Fade transition for the modal mask */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Slide-up transition for the popup */
.popup-enter-active, .popup-leave-active {
  transition: all 0.3s ease;
}
.popup-enter, .popup-leave-to /* .popup-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}
</style>
